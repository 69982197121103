@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Finger+Paint&display=swap');
* {
  font-family: "Lato", sans-serif !important;
  /* overflow-x: hidden; */
  box-sizing: border-box;
}

body { margin: 0 !important; }

@keyframes flash {
  0% {
    background-color: #cfcfcf; /* White */
    
  }
  50% {
    background-color: #ff0000; /* Red */
  }
  100% {
    background-color: #0000ff; /* Blue */
  }
}

.flashing {
  animation: flash 2s infinite;
  border-radius: 16px;
}

.meme-text {
  font-family: "Finger Paint", sans-serif !important;
  overflow: hidden;
}

/* voting system */
/* General body styling */
body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

/* Flexbox setup for centering the app content */
.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

/* Responsive text sizes using clamp */
.Heading {
  font-size: clamp(2rem, 5vw, 5rem); /* Adjust based on your preference */
}

.Text, .Button {
  font-size: clamp(0.8rem, 1.5vw, 1.2rem);
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 90vw; /* Responsive slide width */
  max-width: 500px; /* Max width for larger screens */
}

/* Reduce padding and margin on larger screens */
@media (min-width: 768px) {
  .Button, .Text {
    padding: 0.5rem 1rem;
  }
}
